/**
  Using 11-point scale of 50 to 1000, with
  * 50 being the lightest tint
  * 1000 being the darkest shade
  * 500 representing the primary color.
*/

export const red = {
  50: "#FEF6F6",
  100: "#FBD2D4",
  200: "#F8AEB1",
  300: "#F58B8E",
  400: "#F2676C",
  500: "#EF4349",
  600: "#EC1B22",
  700: "#C91118",
  800: "#A10E13",
  900: "#790A0E",
  1000: "#510709",
};

export const purple = {
  50: "#F8F7FE",
  100: "#DCD9F9",
  200: "#BFBBF5",
  300: "#A39DF0",
  400: "#867FEC",
  500: "#6A61E7",
  600: "#4438E1",
  700: "#2A1FCB",
  800: "#2219A3",
  900: "#19137A",
  1000: "#110C51",
};

export const gray = {
  50: "#F7F8F9",
  100: "#D8DBE2",
  200: "#BABFCA",
  300: "#9BA2B3",
  400: "#7C869C",
  500: "#616B80",
  600: "#535C6E",
  700: "#454C5B",
  800: "#373D49",
  900: "#2A2E37",
  1000: "#1C1F25",
};

export const green = {
  50: "#B9FFE2",
  500: "#00A15D",
  1000: "#007644",
};

export const blue = {
  50: "#E2F5FA",
  500: "#6FCEE7",
  1000: "#24B2D7",
};

export const yellow = {
  50: "#FFF7CC",
  500: "#FFD600",
  1000: "#FFC659",
};

export const opacity = {
  "white-0": "rgba(255, 255, 255, 0)",
  "white-50": "rgba(255, 255, 255, .5)",
  "white-70": "rgba(255, 255, 255, .7)",
  "black-08": "rgba(0, 0, 0, 0.08)",
  "black-80": "rgba(0, 0, 0, 0.8)",
  "black-38": "rgba(0, 0, 0, 0.38)",
  "black-54": "rgba(0, 0, 0, 0.54)",
};

export default {
  text: "#000",
  background: "#fff",
  line: opacity["black-8"],
  primary: red[500],
  secondary: purple[500],
  white: "#fff",
  black: "#000",
  transparent: opacity["white-0"],
  border: opacity["black-08"],

  // primary shades
  snow: red[50],
  mistyrose: red[100],
  lightpink: red[200],
  lightcoral: red[300],
  salmon: red[400],
  crimson: red[500],
  lightfirebrick: red[600],
  firebrick: red[700],
  darkfirebrick: red[800],
  maroon: red[900],
  darkMaroon: red[1000],

  // secondary shades
  ghostwhite: purple[50],
  lavender: purple[100],
  thistle: purple[200],
  cornflowerblue: purple[300],
  mediumpurple: purple[400],
  mediumslateblue: purple[500],
  blueviolet: purple[600],
  mediumblue: purple[700],
  darkblue: purple[800],
  midnightblue: purple[900],
  darkmidnightblue: purple[1000],

  // gray shades
  whitesmoke: gray[50],
  gainsboro: gray[100],
  silver: gray[200],
  darkgray: gray[300],
  lightslategray: gray[400],
  slategray: gray[500],
  dimgray: gray[600],
  darkslategray: gray[700],
  deepdarkslategray: gray[800],
  muchdeepdarkslategray: gray[900],
  blackdarkslategray: gray[1000],

  // additional colors
  aliceblue: blue[50],
  skyblue: blue[500],
  deepskyblue: blue[1000],
  lemonchiffon: yellow[50],
  gold: yellow[500],
  goldenrod: yellow[1000],
  paleturquoise: green[50],
  mediumseagreen: green[500],
  seagreen: green[1000],

  red,
  purple,
  blue,
  green,
  yellow,
  gray,
  opacity,
};
