/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import { TriggerContentProps, DetailsProps } from "./types";

import { DIRECTION } from "./index";

export const TriggerContent = React.forwardRef<HTMLButtonElement, TriggerContentProps>(
  ({ onClick, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        onClick={onClick}
        {...rest}
        sx={{
          border: "none",
          margin: 0,
          padding: 0,
          width: "100%",
          height: "auto",
          overflow: "visible",
          outline: "none",
          color: "inherit",
          font: "inherit",
          background: "transparent",
          cursor: "pointer",
        }}
      />
    );
  }
);

const getPosition = (direction, offset) => {
  switch (direction) {
    case DIRECTION.left:
      return {
        top: "0",
        right: `calc(100% + ${offset}px)`,
      };
    case DIRECTION.right:
      return {
        top: "0",
        left: `calc(100% + ${offset}px)`,
      };

    default:
      return {
        top: "0",
        left: `calc(100% + ${offset}px)`,
      };
  }
};

export const DetailsContent = React.forwardRef<HTMLDivElement, DetailsProps>((props, ref) => {
  const { direction, offset, overrideStyles, ...rest } = props;
  return (
    <div
      ref={ref}
      {...rest}
      sx={{
        position: "absolute",
        height: "100%",
        width: "280px",
        background: "#fff",
        zIndex: "modalBackdrop",
        boxShadow: "long",
        overflowX: "hidden",
        overflowY: "auto",
        ...getPosition(direction, offset),
        ...overrideStyles,
      }}
    />
  );
});
