import { Marker, InfoWindow } from "@react-google-maps/api";
import React, { FC } from "react";
import { MarkerWithInfoProps } from "./types";

const MarkerWithInfo: FC<MarkerWithInfoProps> = ({
  stations,
  currentStation,
  renderStationLabel,
  renderStationPopover,
  setSelectedStation,
  setHoveredStation,
  icon,
}) => {
  const stationLabel =
    renderStationLabel &&
    renderStationLabel({
      currentStation,
      stations,
    });

  const stationPopover = renderStationPopover && renderStationPopover(currentStation.id);

  const { id, lat, lng } = currentStation;
  return (
    <Marker
      onClick={() => setSelectedStation && setSelectedStation(id)}
      onMouseOver={() => setHoveredStation && setHoveredStation(id)}
      onMouseOut={() => setHoveredStation && setHoveredStation(undefined)}
      icon={icon}
      position={{ lat, lng }}
      zIndex={2000}
    >
      {stationLabel ? <InfoWindow>{stationLabel}</InfoWindow> : null}

      {stationPopover ? <InfoWindow>{stationPopover}</InfoWindow> : null}
    </Marker>
  );
};

export default MarkerWithInfo;
