const ALERT_VARIANT_OPTIONS = {
  success: {
    message: "Success",
    styleVariant: "alerts.success",
    iconName: "completed",
  },
  warning: {
    message: "Warning",
    styleVariant: "alerts.warning",
    iconName: "warning",
  },
  error: {
    message: "Error",
    styleVariant: "alerts.error",
    iconName: "error",
  },
};

export default ALERT_VARIANT_OPTIONS;
