export default {
  fonts: {
    body: "'Roboto', sans-serif",
    heading: "'Roboto', sans-serif",
  },
  fontSizes: [13, 15, 17, 19, 21, 23, 31, 43],
  fontWeights: {
    bold: 700,
    body: 400,
    heading: 700,
    light: 300,
  },
  text: {
    "title-x-large": {
      fontFamily: "heading",
      fontSize: "43px",
      lineHeight: "50px",
    },
    "title-large": {
      fontFamily: "heading",
      fontSize: "31px",
      lineHeight: "36px",
    },
    "title-medium": {
      fontFamily: "heading",
      fontSize: "23px",
      lineHeight: "27px",
    },
    "title-small": {
      fontFamily: "heading",
      fontSize: "21px",
      lineHeight: "25px",
    },
    headline: {
      fontFamily: "heading",
      fontSize: "19px",
      lineHeight: "22px",
    },
    subhead: {
      fontFamily: "heading",
      fontSize: "17px",
      lineHeight: "20px",
    },
    "body-large": {
      fontFamily: "body",
      fontSize: "15px",
      lineHeight: "18px",
    },
    "body-small": {
      fontFamily: "body",
      fontSize: "13px",
      lineHeight: "15px",
    },
    "caption-large": {
      fontFamily: "body",
      fontSize: "11px",
      lineHeight: "13px",
    },
    "caption-small": {
      fontFamily: "body",
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
};
