/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { FC, useEffect } from "react";
import { AlertProps } from "./types";
import { AlertContainer, CloseIconWrapper, AlertText } from "./styled";

export const Alert: FC<AlertProps> = ({
  closeToast,
  message,
  duration = 3000,
  type,
  id,
}: AlertProps) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (closeToast) {
        closeToast();
      }
    }, duration);
    return () => clearTimeout(timer);
  }, []);

  return (
    <AlertContainer type={type} id={id}>
      <AlertText>{message}</AlertText>
      <CloseIconWrapper onClick={closeToast} />
    </AlertContainer>
  );
};

export default Alert;
