import theme from "@swvl/theme";
import React, { FC } from "react";
import { MarkersProps } from "./types";
import MarkerWithInfo from "./MarkerWithInfo";

const Markers: FC<MarkersProps> = ({
  stations,
  renderStationLabel,
  renderStationPopover,
  setSelectedStation,
  setHoveredStation,
}) => {
  return (
    <>
      {stations.map((station) => {
        const { id, style } = station;
        const icon = {
          path: style?.path || window.google.maps.SymbolPath.CIRCLE,
          scale: style?.scale || 4,
          strokeColor: style?.strokeColor || theme.colors.primary,
          fillColor: style?.fillColor || theme.colors.white,
          fillOpacity: style?.fillOpacity || 1,
          strokeWeight: style?.strokeWeight || 2,
        };
        return (
          <MarkerWithInfo
            key={id}
            icon={icon}
            stations={stations}
            currentStation={station}
            renderStationLabel={renderStationLabel}
            renderStationPopover={renderStationPopover}
            setSelectedStation={setSelectedStation}
            setHoveredStation={setHoveredStation}
          />
        );
      })}
    </>
  );
};

export default Markers;
