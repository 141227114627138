import React from "react";
import useRoveFocus from "./use-rove-focus";
import { MenuWrapper, NoResultsFound } from "./styled";
import ListItem from "./ListItem";
import { MenuProps } from "./types";

const List = <OP extends Record<string, unknown>>({
  suggestions,
  renderOption,
  getOptionKey,
  handleSelect,
  noResultsMessage = "No results found",
}: MenuProps<OP>) => {
  const [focusIndex, setFocusIndex] = useRoveFocus(suggestions.length);
  return (
    <MenuWrapper>
      {suggestions.length > 0 ? (
        suggestions.map((item, index: number) => {
          const itemKey = getOptionKey(item);
          return (
            <ListItem
              setFocus={setFocusIndex}
              isFocused={focusIndex === index}
              key={itemKey}
              index={index}
              onSelectItem={() => {
                handleSelect(item);
              }}
            >
              {renderOption(item)}
            </ListItem>
          );
        })
      ) : (
        <NoResultsFound message={noResultsMessage} />
      )}
    </MenuWrapper>
  );
};

export default List;
