/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect, useCallback, useRef, FC } from "react";
import { MenuItemWrapper } from "./styled";
import { MenuItemProps } from "./types";

const ListItem: FC<MenuItemProps> = ({ children, isFocused, index, setFocus, onSelectItem }) => {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const node = ref.current;
    if (isFocused && node) {
      node.focus();
    }
  }, [isFocused]);

  const handleSelect = useCallback(() => {
    onSelectItem();
    setFocus(index);
  }, [index, onSelectItem, setFocus]);

  return (
    <li
      role="option"
      tabIndex={isFocused ? 0 : -1}
      ref={ref}
      onKeyPress={handleSelect}
      aria-selected={isFocused}
      sx={{
        "&:focus": {
          background: "ghostwhite",
          outline: "none",
        },
      }}
    >
      <MenuItemWrapper onClick={handleSelect}>{children}</MenuItemWrapper>
    </li>
  );
};

export default ListItem;
