/** @jsx jsx */

/*
 * TODO:
 * consider using renderProps pattern instead of compound component
 * pattern as we want to separate triggers from details
 */

import { jsx } from "theme-ui";
import React, { FC, Children, useEffect, useCallback, useRef } from "react";

import { TriggerContent, DetailsContent } from "./styled";
import { ContextProps, PanelProps, TriggerProps, DetailsProps, PanelComposition } from "./types";

export const DIRECTION = {
  left: "left",
  right: "right",
};

export const SIZE = {
  full: "100%",
  small: "small",
};

const panelContext = React.createContext<ContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentTriggerId: () => {},
  currentTriggerId: null,
  triggersRef: null,
  detailsRef: null,
});

const usePanelContext = () => {
  const context = React.useContext(panelContext);
  if (!context) {
    throw new Error(
      "details panel compound components can't be rendered outside the panel component"
    );
  }
  return context;
};

export const Trigger: FC<TriggerProps> = (props) => {
  const { id } = props;
  const { setCurrentTriggerId, currentTriggerId } = usePanelContext();
  return (
    <TriggerContent
      onClick={() => {
        setCurrentTriggerId(currentTriggerId !== id ? id : null);
      }}
    >
      {props.children}
    </TriggerContent>
  );
};

export const Details: FC<DetailsProps> = ({
  direction = "right",
  offset = 0,
  children,
  ...rest
}) => {
  const { currentTriggerId, detailsRef } = usePanelContext();
  return (
    <React.Fragment>
      {currentTriggerId ? (
        <DetailsContent ref={detailsRef} direction={direction} offset={offset} {...rest}>
          {children}
        </DetailsContent>
      ) : null}
    </React.Fragment>
  );
};

const Panel: FC<PanelProps> & PanelComposition = (props) => {
  const { clickOutSideToClose = true, setCurrentTriggerId } = props;

  const detailsRef = useRef<HTMLDivElement>(null);
  const triggersRef = useRef<HTMLDivElement>(null);

  const esc = useCallback((e) => {
    if (e.keyCode === 27) {
      setCurrentTriggerId(null);
    }
  }, []);

  const clickListener = useCallback((e) => {
    if (triggersRef.current?.contains(e.target)) {
      return;
    }
    const node = detailsRef.current;
    if (clickOutSideToClose && !node?.contains(e.target as Node)) {
      setCurrentTriggerId(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", esc);
    document.addEventListener("click", clickListener, true);
    return () => {
      document.removeEventListener("keydown", esc);
      document.removeEventListener("click", clickListener, true);
    };
  }, [esc, clickListener]);

  const { containerStyles, children } = props;
  const triggers: React.ReactNode[] = [];
  const details: React.ReactNode[] = [];

  /* this not be the cleanest way so we consider using renderProps patter instead  */
  Children.forEach(children, (child) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const type = child.type;
    if (type === Trigger) {
      triggers.push(child);
    } else if (type === Details) {
      details.push(child);
    }
  });

  return (
    <panelContext.Provider value={{ ...props, triggersRef, detailsRef }}>
      <div sx={{ ...containerStyles }}>
        <div ref={triggersRef}>{triggers}</div>
        {details}
      </div>
    </panelContext.Provider>
  );
};

Panel.Trigger = Trigger;
Panel.Details = Details;

export * from "./types";
export default Panel;
