import { useEffect, useState, useRef, useCallback } from "react";
import { Location, BusAnimationOptions, BusIconOptions } from "./types";
import { BUS, BUS_ANIMATION } from "./defaults";
import isNil from "lodash/fp/isNil";

const useRenderBus = ({
  rideId,
  currentBusLocation,
  busIconOptions = {},
  busAnimationOptions = {},
  map,
}: {
  rideId: string;
  currentBusLocation: Location | null;
  busIconOptions: BusIconOptions;
  busAnimationOptions?: BusAnimationOptions;
  map: google.maps.Map | null;
}) => {
  const [activeLocation, setActiveLocation] = useState<Location | null>(null);

  /** Animation */
  const {
    duration = BUS_ANIMATION.duration,
    maxOffset = BUS_ANIMATION.maxOffset,
  }: BusAnimationOptions = busAnimationOptions;

  const {
    lowerPath = BUS.lowerPath,
    upperPath = BUS.upperPath,
    lowerPathColor = BUS.lowerPathColor,
    upperPathColor = BUS.upperPathColor,
    width = BUS.width,
    height = BUS.height,
    scale = BUS.scale,
  }: BusIconOptions = busIconOptions;

  const animationPolylineRef = useRef<google.maps.Polyline>();
  const animationFrameRef = useRef<number>();
  const animationStartTimeRef = useRef<number>();
  const animationDurationRef = useRef<number>();
  const minAnimationDurationRef = useRef<number>(duration);

  const setAnimationPolylineOffset = (offset: number) => {
    const icons = animationPolylineRef.current?.get("icons");
    icons[0].offset = `${offset}%`;
    icons[1].offset = `${offset}%`;
    animationPolylineRef.current?.set("icons", icons);
  };

  const removeAnimationPolyline = () => {
    if (!isNil(animationPolylineRef.current)) {
      animationPolylineRef.current.setMap(null);
      animationPolylineRef.current = undefined;
    }
  };

  const clearAnimationTimers = () => {
    if (!isNil(animationFrameRef.current)) {
      window.cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = undefined;
      animationStartTimeRef.current = undefined;

      /** Keep track of the minimum animation duration so far */
      minAnimationDurationRef.current = Math.min(
        animationDurationRef.current ?? 0,
        minAnimationDurationRef.current
      );
      animationDurationRef.current = undefined;
    }
  };

  const animateBus = (timestamp: number) => {
    if (animationStartTimeRef.current) {
      /** Calculate elapsed time from the start of the animation */
      const elapsedTime = Math.floor(timestamp - animationStartTimeRef.current);

      /** Keep log of the current animation duration */
      animationDurationRef.current = elapsedTime;

      /** Calculate the new offset */
      const offset = Math.min((elapsedTime / minAnimationDurationRef.current) * 100, maxOffset);

      /** Set new animation offset */
      setAnimationPolylineOffset(offset);

      /** Animate as long as max offset not reached */
      if (offset < maxOffset) {
        animationFrameRef.current = window.requestAnimationFrame(animateBus);
      } else {
        /** Done with animation, reset timers*/
        clearAnimationTimers();
      }
    } else {
      /** Set start time initially */
      animationStartTimeRef.current = timestamp;
      /** Continue animation */
      animationFrameRef.current = window.requestAnimationFrame(animateBus);
    }
  };

  const moveBus = useCallback(() => {
    if (map && !isNil(currentBusLocation)) {
      const currentActiveLocation = activeLocation || currentBusLocation;
      const path = [
        { ...currentActiveLocation, rotation: currentActiveLocation?.bearing },
        { ...currentBusLocation, rotation: currentBusLocation?.bearing },
      ];

      /** Create a new invisible polyline from the current location to the next location to
       * animate the bus on it */
      animationPolylineRef.current = new google.maps.Polyline({
        path,
        strokeWeight: 0,
        strokeOpacity: 0.00001,
        zIndex: 10000,
        icons: [
          {
            /** Lower path of the bus icon */
            icon: {
              path: lowerPath,
              scale: scale,
              fillColor: lowerPathColor,
              fillOpacity: 1,
              anchor: new window.google.maps.Point(width / 2, height / 2),
            },
            offset: "0%",
          },
          {
            /** Upper path of the bus icon */
            icon: {
              path: upperPath,
              scale: scale,
              fillColor: upperPathColor,
              fillOpacity: 1,
              anchor: new window.google.maps.Point(width / 2, height / 2),
            },
            offset: "0%",
          },
        ],
        map,
      });

      /** Start animating the bus on the created polyline */
      animationFrameRef.current = window.requestAnimationFrame(animateBus);
    }
    /** Set the new bus location */
    setActiveLocation(currentBusLocation);
  }, [currentBusLocation, JSON.stringify(busIconOptions)]);

  /** Moving bus effect, updates on every new location & cleanup the previous animations */
  useEffect(() => {
    /** Move & animate bus on a new tracking polyline */
    moveBus();

    return () => {
      /** Remove previous animation polyline */
      removeAnimationPolyline();
      /** Clear previous animation & reset timers */
      clearAnimationTimers();
    };
  }, [currentBusLocation, JSON.stringify(busIconOptions)]);
};

export default useRenderBus;
